<template>

  <div v-if="data" class="pb text-style-info">

    <div class="html_into" v-html="data.body"></div>

  </div>

</template>

<script setup>

const { $api, $head } = useNuxtApp();

const loadData = async () => {
  return await $api.getStaticPage('terms');  
}


const { pending: is_loading,  data: data } = await useLazyAsyncData('data', async () => await loadData())



useHead(useNuxtApp().$head.getStaticPage());
 

</script>

<style scoped>

.text-style-info{
  text-indent: 2rem;
}

.html_into :v-deep(.de_im){
  height: 14px;
  vertical-align: middle;
}
 
</style>